.main-footer{
	@include media-breakpoint-down(xs){
		margin-top: 4rem;
	}
	margin-top: 7rem;
	background-color: $gray-200;
}

.footer-location:hover{
	background-color: $primary !important;
	cursor: pointer;
	div{
		div.col-auto{
			svg{
				color: $white !important;
			}
		}
		div.col{
			p{
				color: $white !important;
			}
		}
	}
}

.footer-whatsapp:hover{
	background-color: darken($green, 15%) !important;
	cursor: pointer;
}