.autocomplete {
  /*the container must be positioned relative:*/
  position: relative;
}
.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}
.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff; 
  border-bottom: 1px solid #d4d4d4; 
}
.autocomplete-items div:hover {
  /*when hovering an item:*/
  background-color: $primary;
  color: $white;
}
.autocomplete-active {
  /*when navigating through the items using the arrow keys:*/
  background-color: $primary !important; 
  color: #ffffff; 
}