#index-feature{
	@include media-breakpoint-down(xs) {
		border: none;
	}
	border : 2px solid $gray-300;
	img{
		@include media-breakpoint-down(xs){
			width: 2.25rem;
			height: 2.25rem;
		}
	}
}
.index-banner-bottom{
	border: 1px solid $gray-300;
}

.book1-container, .book2-container, .banner-tinycarousel-container, .newArrivals-container, .bestSeller-container, .relatedBooks-container{
	@include media-breakpoint-only(md){
		max-height: 275px;
	}
	@include media-breakpoint-only(lg){
		max-height: 315px;
	}
	@include media-breakpoint-only(xl){
		max-height: 360px;
	}
	.controls{
		.prev{
			transition: .3s ease-in-out;
			display: flex;
			width: 2rem;
			height: 2rem;
			background-color: $white;
			border-radius: 2rem;
			position: absolute;
			left: 0rem;
			top: 35%;
			transform: translateY(-50%);
			z-index: 1;
			box-shadow: none;
			opacity: 0;
		}
		.next{
			transition: .3s ease-in-out;
			display: flex;
			width: 2rem;
			height: 2rem;
			background-color: $white;
			border-radius: 2rem;
			position: absolute;
			right: 0rem;
			top: 35%;
			transform: translateY(-50%);
			z-index: 1;
			box-shadow: none;
			opacity: 0;
		}
	}
	&:hover{
		.controls{
			.prev{
				left: 1.5rem;
				box-shadow: $box-shadow;
				cursor: pointer;
				opacity: 1;
			}
			.next{
				right: 1.5rem;
				box-shadow: $box-shadow;
				cursor: pointer;
				opacity: 1;
			}
		}
	}
}

.main-banner-container{
	.controls{
		.prev{
			transition: .3s ease-in-out;
			display: flex;
			width: 2rem;
			height: 2rem;
			background-color: $white;
			border-radius: 2rem;
			position: absolute;
			left: 0rem;
			top: 50%;
			transform: translateY(-50%);
			z-index: 1;
			box-shadow: none;
			opacity: 0;
		}
		.next{
			transition: .3s ease-in-out;
			display: flex;
			width: 2rem;
			height: 2rem;
			background-color: $white;
			border-radius: 2rem;
			position: absolute;
			right: 0rem;
			top: 50%;
			transform: translateY(-50%);
			z-index: 1;
			box-shadow: none;
			opacity: 0;
		}
	}
	&:hover{
		.controls{
			.prev{
				left: 1.5rem;
				box-shadow: $box-shadow;
				cursor: pointer;
				opacity: 1;
			}
			.next{
				right: 1.5rem;
				box-shadow: $box-shadow;
				cursor: pointer;
				opacity: 1;
			}
		}
	}
}