.nav.nav-tabs{
	border-bottom: 2px solid $primary;
	.nav-item{
		.nav-link{
			font-size: 0.875rem;
			border: none !important;
			&.active{
				background-color: $primary;
				color: $white;
			}
			&:hover{
				background-color: $primary;
				color: $white;
			}
		}
	}
}