@import 'banner', 'book', 'modals', 'sortfilter-xs', 'autocomplete';

.text-line-ba {
  line-height: 0;
  text-align: center;
  width: 100%;
}
.text-line-ba span {
  display: inline-block;
  position: relative;
  width: 100%;  
}
.text-line-ba span:before,
.text-line-ba span:after {
  content: "";
  position: absolute;
  height: 1;
  border-bottom: 1px solid $secondary;
  top: 0;
  width: calc(50% - 80px);
}
.text-line-ba span:before {
  right: 0;
  // margin-left: 15px;
}
.text-line-ba span:after {
  left: 0;
  // margin-left: 15px;
}