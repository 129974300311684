.custom-checkbox{
	margin-bottom: 1rem;
	input[type=checkbox] + label{
		transition: .2s ease-in-out;
		width: 100%;
		cursor: pointer;
		color: $gray-800;
		// font-weight: 400;
	}
	input[type=checkbox]:checked{
		color: $primary;
		+ label{
			font-weight: 500;
			color: $gray-800;
		}
	}
	input[type=checkbox]:hover{
		color: $primary;
		+ label{
			font-weight: 500;
			color: $gray-800;
		}
	}
}

#filterKategoriAccordion, #filterAccordion{
	.card{
		border: none;
		.card-header{
			background-color: transparent;
			border: none;
			padding: 0;
			button.btn-link{
				position: relative;
				font-weight: 500;
				width: 100%;
				text-align: left;
				padding: .5rem 1.25rem .5rem 0;
				padding-right: 1.25rem;
				color: $primary;
				span.card-header-angle{
					width: 1rem;
					height: 1rem;
					position: absolute;
					right: 0;
					top: 50%;
					transform: rotate(180deg) translateY(50%);
					i{
						width: 100%;
						text-align: center;
					}
				}
				span.badge{
					transition: .3s ease-in-out;
					margin-left: .5rem;
					border-radius: 1rem;
					background-color: $primary;
				}
				&.collapsed{
					color: $gray-800;
					span.card-header-angle{
						transform: rotate(0deg) translateY(-50%);
					}
					span.badge{
						background-color: $gray-800;
					}
				}
				&:hover{
					color: $primary;
					span.badge{
						background-color: $primary;
					}
				}
			}
		}
		.card-body{
			padding: 0;
			button.btn-link{
				font-weight: 500;
				text-align: left;
				color: $gray-800;
				padding: .5rem 1rem;
				width: 100%;
				span.badge{
					margin-left: .5rem;
					border-radius: 1rem;
					background-color: $gray-800;
				}
				&:hover{
					color: $primary;
					span.badge{
						background-color: $primary;
					}
				}
			}
		}
	}
}

.breadcrumb{
	padding: 0;
	background-color: $white;
	.breadcrumb-item{
		color: $primary;
		&.active{
			color: $secondary;
		}
	}
}

.list-group-item-action:hover{
	background-color: darken($color: $primary, $amount: 8%);
	color: $white;
}

.kartuMember-container{
	width: 100%;
	padding-bottom: calc((100% / 8) * 5);
	border: 1px solid $gray-400;
	border-radius: .5rem;
	position: relative;
	overflow: hidden;
	.kartuMember-logo{
		width: 25%;
		position: absolute;
		top: 5%;
		left: 4%;
	}
	.kartuMember-theme{
		width: calc((100% / 8) * 5);
		padding-bottom: calc((100% / 8) * 5);
		border-top-left-radius: 50%;
		border-bottom-left-radius: 50%;
		border-top-right-radius: 50%;
		border-bottom-right-radius: 50%;
		position: absolute;
		right: -25%;
		background-position: center;
		background-size: cover;
	}
	.kartuMember-tulisan{
		width: 55%;
		position: absolute;
		bottom: 5%;
		left: 4%;
		// font-size: 100;
		.kartuMember-nama{
			// font-size: calc((100% / 8) * 5);
			line-height: 100%;
			width: 100%;
			// font-size: 50%;
		}
	}
}

.clamp{
	display: -webkit-box;
	overflow: hidden;
	&.clamp-2{
		-webkit-line-clamp: 2;
	}
}

.small-875{
	font-size: 87.5%;
	line-height: 175%;
	@include media-breakpoint-down(sm){
		font-size: 100%;
	}
}

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
		@each $ch-sizes, $length in $ch-sizes{
			.mw#{$infix}-#{$ch-sizes}{
				max-width: $ch-sizes+ch;
			}
		}
	}
}

.table thead th{
	vertical-align: middle;
}