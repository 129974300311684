#navbar-master-master{
	@include media-breakpoint-down(md){
		background-position: calc(100% + 1rem);
		img{
			width: 81px;
			height: 41.4px;
			position: absolute;
			left: 50%;
			top: -.3rem;
			@include media-breakpoint-between(md, md){
				top: 0;
				left: 6.5rem;
			}
			transform: translateX(-50%);
		}
		.fa-shopping-cart{
			color: $primary;
			// box-shadow: $box-shadow;
		}
	}
	.container{
		position: relative;
	}
	.navbar-toggler{
		position: absolute;
		left: .5rem;
		padding: 0;
		border: none;
		@include media-breakpoint-between(md, md){
			left: 1rem;
		}
	}
	padding: 1rem;
	background-position: right;
	background-size: contain;
	background-repeat: no-repeat;
	ul.navbar-nav{
		width: 100%;
		@include media-breakpoint-down(md){
			width: auto;
		}
		@include media-breakpoint-between(md, md){
			width: 100%;
			flex-direction: row;
			form.form-inline{
				margin: 0 !important;
			}
		}
		li.nav-item{
			a.nav-link{
				color: $secondary;
				font-size: $font-size-sm;
				padding: $spacer*.5 $spacer;
				font-weight: 700;
				&:hover{
					color: $primary;
				}
				i.fas{
					font-size: $font-size-base;
				}
			}
			&.active{
				color: $primary;
			}
		}
		@include media-breakpoint-between(md, md){
			li.col.p-0{
				margin-left: 10rem;
				margin-right: 1rem;
			}
			#shoppingcartLink{
				margin-right: .5rem;
			}
		}
	}
}

#navbar-sekunder{
	position: fixed;
	width: 100%;
	display: flex;
	top: 80px;
	margin: 0;
	z-index: 2;
	transition: top .3s;
	div.container{
		ul.navbar-nav{
			width: 100%;
			@include media-breakpoint-down(sm){
				width: calc(100% - 1px);
			}
			li.nav-item{
				height: auto;
				.nav-link{

					font-size: $font-size-base*.75;
					font-weight: 700;
					color: $white;
				}
				&:hover{
					background-color: darken($primary, 15%);
				}
				&#langtxt:hover{
					background-color: $primary;
				}
			}
		}
		#search-sm{
			input.form-control{
				border-radius: .5rem 0 0 .5rem;
			}
			button.btn{
				color: $primary;
				background-color: $white;
				border-radius: 0 .5rem .5rem 0;
			}
		}
	}
}

#search-sm-container{
	background-color: $primary;
	position: fixed;
	z-index: 2;
	transition: top .3s;
	top: 60px;
	input.form-control{
		border-radius: .25rem 0 0 .25rem;
	}
	button{
		background-color: $white;
		border-radius: 0 .25rem .25rem 0;
	}
}

.category-md{
	display: none;
}

.category-md-active{
	display: block;
	position: fixed;
	top: 114px;
	background-color: $gray-100;
	z-index: 1001;
	padding: 4rem 0;
	transition: .3s;
	.container{
		.row{
			.col-4{
				padding: 1.25rem 1rem;
				border-right: 2px solid $gray-600;
				.nav.nav-pills{
					.nav-link{
						font-weight: 700;
						text-align: right;
						color: $gray-600;
						padding: 0.25rem 1rem;
						&.active{
							background-color: transparent;
							color: $primary;
						}
						&:hover{
							background-color: transparent;
							color: $primary;
						}
					}
				}
			}
			.col-8{
				.tab-content{
					.tab-pane{
						.row{
							.col-12{
								padding: 1.25rem 3rem;
								.row{
									.col-6{
										background-color: transparent;
										border: none;
										padding: .25rem 0;
										a{
											font-size: .875rem;
											color: $gray-600;
											text-decoration: none;
											&:hover{
												font-weight: 700;
												color: $primary;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

#menu{
	.modal-dialog .modal-content{
		min-width: 100%;
		min-height: calc(100vh - 60px);
	}
}

.menu{
	transition: .3s ease-in-out;
	z-index: 1010;
	position: fixed;
	&.menu-xs{
		width: 100vw;
		top: -100vh;
		height: 100vh;
		@include media-breakpoint-up(sm){
			height: auto;
			padding-bottom: 2rem;
			box-shadow: $box-shadow-sm;
		}
		background-color: $white;
		a{
			font-size: 1rem;
			font-weight: 500;

		}
	}
	&.menu-xs-active{
		top: 60px;
		@include media-breakpoint-between(md,md){
			top: 70px;
		}
	}
}

#navbar-img{
	@include media-breakpoint-down(md){
		img{
			width: 81px;
			height: 41.4px;
		}
	}
	padding: 1rem;
}

#suggestion_list, #suggestion_list_sm{
	list-style: none;
	padding: 0;
	position: absolute;
	z-index: 1000;
	font-size: .875rem;
	box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.15) !important;
	@include media-breakpoint-down(sm){
		left: 1rem;
		top: 2.9rem;
		width: calc(100% - 2rem);
	}
	@include media-breakpoint-only(md){
		top: 2.1rem;
		left: 0;
		width: 100%;
	}
	@include media-breakpoint-up(lg){
		top: 2.1rem;
		left: 1rem;	
		width: calc(100% - 6.8rem - 1px);
	}
	li{
		padding: .5rem 1rem;
		background-color: white;
		border-bottom: 1px solid $gray-300;
		&:last-child{
			border-bottom: 0;
		}
		&:hover, &:focus, &:active{
			background-color: $primary;
			color: white;
		}
		
	}
}

#cart_count{
	position: absolute;
	right: .5rem;
	top: 0rem;
	border-radius: .5rem;
}