.book{
	
	.book-ratio{
		width: 100%;
		padding-bottom: calc((100% / 100) * 152.94);
		background-position: center;
		background-size: cover;
		&:hover{
			cursor: pointer;
			box-shadow: $box-shadow;
			-webkit-transition: all .2s ease-in-out;
			   -moz-transition: all .2s ease-in-out;
			    -ms-transition: all .2s ease-in-out;
			     -o-transition: all .2s ease-in-out;
			        transition: all .2s ease-in-out;
		}
	}

	.fa-bookmark{
		top: -1px;
		left: 5%;
		font-size: 1.25rem;
	}

	.book-title{
		display: -webkit-box;
		-webkit-line-clamp: 3;
		@include media-breakpoint-up(lg){
			-webkit-line-clamp: 2;
		}
		overflow: hidden;
		text-overflow: ellipsis;
		color: $primary;
		font-weight: 500;
		font-size: .875rem;
		@include media-breakpoint-down(sm){
			font-size: 1rem;
		}
		margin: .5rem 0 0 0;
		&.opt2{
			font-size: 1rem;
			margin: 0;
			-webkit-line-clamp: 2;
			font-weight: 700;
			line-height: 120%;
		}
	}

	.book-author{
		font-size: .75rem;
		margin-bottom: .5rem;
		p{
			display: inline-block;
			color: $gray-600;
			margin-bottom: 0;
		}
		a{
			color: $cyan;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
	}

	.price-discount{
		font-size: .75rem;
		font-style: italic;
		font-weight: $fw-light;
		color: $gray-600;
		text-decoration: line-through;
		margin: 0;
	}

	.price{
		font-size: .875rem;
		font-weight: $fw-bold;
		color: $primary;
		margin: 0;
	}
}