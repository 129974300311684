.loading {
  position: relative;
  background-color: #E2E2E2;

  &#skeleton_section311{
    padding-bottom: 540px;
  }
  
  &.card-image {
    border-radius: 0;
  }

  &.skeleton_cart {
    height: 22rem
  }

  &.book-ratio_skeleton{
    padding-bottom: calc((100% / 100) * 152.94);
    width: 100%
  }

  &.info_book_skeleton{
    height: 1.25rem
  }

  &.skeleton_promote{
    height: 9rem
  }

  &.skeleton_cart_list{
    height: 23rem;
  }
  
  &::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-15%);
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, .2), transparent);
    animation: loading 1.5s infinite;
  }
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

.card-title.loading {
  height: 1.8rem;
}

.card-image.image {
  max-width: 100%;
  height: auto;
}

.card-description.loading {
  height: 80px;
}