@include media-breakpoint-up(xl){
	.modal{
		padding-right: 0 !important;
	}
}
body{
	padding-right: 0 !important;
	.modal-open{
		padding-right: 0 !important;
	}
}