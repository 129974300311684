//colors
$primary : #91272B;

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #EB3F4F;
$orange:  #fd7e14 !default;
$yellow:  #FFCB2E;
$green:   #2DBB4E;
$teal:    #20c997 !default;
$cyan:    #3BBDD1;

$white:    #fff !default;
$gray-100: #f0f4f7 !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$secondary : $gray-800;

//font
$font-family-sans-serif: 'Roboto', sans-serif;
$headings-font-weight: 700;
$small-font-size: 75%;
$fw-thin: 100;
$fw-light: 300;
$fw-regular: 400;
$fw-med: 500;
$fw-bold: 700;
$fw-black: 900;


//all custom
$body-color: $gray-800;
$enable-rounded: false;
$spacer : 1rem;
$navbar-padding-y: $spacer * 1.375;
$link-color: $gray-800;
$link-hover-color: $primary;
$nav-link-hover: rgba($primary, .7);
$box-shadow: 0 .3rem .5rem rgba(0,0,0,.15)!important;

$ch-sizes: () !default;
$ch-sizes: map-merge(
  (
    10: 10ch,
  	20: 20ch,
    30: 30ch,
    40: 40ch,
    50: 50ch,
    60: 60ch,
    90: 90ch,
    120: 120ch
  ),
  $ch-sizes
);

.btn{
  background-color: white;
}

.rltv{
  position: relative;
}
.abs{
  position: absolute;
}
