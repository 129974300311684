.banner1{
	width: 100%;
	// height: 100%;
	// padding-bottom: calc(100%);
	background-position: center;
	background-size: cover;
	// &.main-banner-1{
	// 	height: 0 !important;
	// 	padding-bottom: calc(20%);
	// }
}

.banner-31{
	width: 100%;
	padding-bottom: calc(100% / 3);
	background-position: center;
	background-size: cover;
}

.banner-11{
	width: 100%;
	padding-bottom: calc(100%);
	background-position: center;
	background-size: cover;
}

.banner-43{
	width: 100%;
	padding-bottom: calc((100% / 4) * 3);
	background-position: center;
	background-size: cover;
}

.banner-169{
	width: 100%;
	padding-bottom: calc((100% / 16)*9);
	background-position: center;
	background-size: cover;
}