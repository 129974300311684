.btn-xs-sort{
 border-top-left-radius: .25rem;
}
.btn-xs-filter{
	border-top-right-radius: .25rem;
	margin-left: -.25rem;
}
#sortfilter-xs-container{
	transition: .3s ease-in-out;
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 1028;
	padding-bottom: 1rem;
}
#sortfilter-xs-content-container{
	height: calc(100vh - 60px);
	position: fixed;
	top: -100vh;
	z-index: 1029;
	overflow-y: scroll;
	transition: .3s ease-in-out;
	@include media-breakpoint-only(sm){
		margin-left: -1.3rem;
	}
}